.layer-control {

  background: white;
  padding: 10px;
  padding-top:35px;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 90vh;
  overflow-y: auto;
  width: 350px; /* Set a fixed width */
}

.layer-control .MuiListItem-root {
  padding: 0 8px; /* Reduce padding */
}

.layer-control .MuiListItemText-root {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.layer-control .MuiListItemSecondaryAction-root {
  right: 0;
}
