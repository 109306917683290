/* PaperList.scss */

.paperListContainer {
  height: calc(100vh - 85px);
  width: 100%;
  background-color: #ffffff;
}

.virtualizedList {
  padding: 0;
}

.paperListItemParent {
  box-sizing: border-box;
  padding: 0px;
  border-bottom: 1px solid #e9e9e9;
}

.paperListItemParent.error {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.paperListItem {
  //background-color: #fafafa;
  border-radius: 6px; // Slightly smaller radius
  padding: 5px; // Reduced padding
  //box-shadow: 0 1px 2px rgba(0,0,0,0.05); // Subtle shadow
  //transition: background-color 0.3s, box-shadow 0.3s;
  height: 100%;
  gap:3px;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    background-color: #f0f4f8;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }

  &:focus {
    outline: none;
    box-shadow: none; // Remove the blue outline box-shadow
  }
  
  &:focus-visible {
    outline: none; // Remove default focus outline
  }
}

.paperHeader {
  margin-bottom: 4px;
}

.paperTitle {
  font-size: 0.875rem; // Reduced font size
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 3; // Limit to 3 lines
  line-clamp: 3; // Standard property for compatibility
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.1;
}
.paperBody {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 4px;
}

.paperYear {
  font-size: 0.75rem; // Smaller font size
}

.paperFooter {
  display: flex;
  align-items: center;
  gap: 5px;
}

.citationCounter {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: #e3f2fd;
  padding: 2px 6px; // Reduced padding
  font-size: 0.75rem; // Smaller font size
  color: #1565c0;
}

.citationCounter.withBorder {
  border: 1px solid #d9dadb;
}

.citationCounter .icon {
  margin-right: 3px; // Reduced margin
}

.citationText {
  padding-left: 3px; // Adjusted padding
}

.authorDisplay {
  display: flex;
  flex-wrap: wrap;
  gap: 3px; // Reduced gap between chips
}

.author {
  font-size: 0.75rem; // Smaller font size
  color: #1976d2;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.skeleton {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

/* Cluster Header Styles */
.paperListclusterHeader {
  background-color: white;
  padding: 8px 12px;
  padding-left: 0px;
  border-bottom: 1px solid #ebebeb;
  display: flex;
  align-items: center;
  height: 30px !important;
  gap: 8px;
  width: 367px !important;

  &:hover .paperListclusterActions {
    visibility: visible;
    opacity: 1;
  }

  .paperListclusterHeaderContent {
    display: flex;
    align-items: center;
    flex: 1;
    min-width: 0; /* Allows text truncation */
  }

  .paperListclusterActions {
    display: flex;
    gap: 4px;
    margin-left: auto; /* Pushes focus icon to the right */
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
}

/* Subheader Styles */
.paperListSubheader {
  background-color: #e8eaf6; /* Light background color */
  padding: 8px 12px;
  border-bottom: 1px solid #c5cae9;
  font-weight: bold;
  font-size: 14px;
}

.paperListSubheader:hover {
  background-color: #d1c4e9;
}

/* Cluster Label Styles */
.paperListclusterLabel {
  font-weight: bold;
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align:left;

}

.paperListclusterLabel:hover {
  cursor: pointer;
}

/* Cluster Actions Styles */
.paperListclusterActions {
  display: flex;
  gap: 4px;
  margin-left: auto; /* Pushes focus icon to the right */
}